/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable, signal, inject, TransferState, makeStateKey, StateKey, computed } from "@angular/core";
import { Router } from "@angular/router";
import { collection, doc, Firestore, getDoc, setDoc } from "@angular/fire/firestore";
import { Auth, User } from "@angular/fire/auth";
import { AppUser } from "../models/appUser.model";
import { LoadingService } from "@awo-care/shared-core";

const LOGIN_URL = 'auth/login';

const claimHierarchy: { [key: string]: string[]; } = {
  user: ['admin'],
  admin: []
};

@Injectable({
  providedIn: "root"
})
export class UserService {
  auth = inject(Auth);
  loadingService = inject(LoadingService);

  public currentUser = signal({} as User);
  public isLoggedIn = signal(false);
  private userClaimsSignal = signal({} as unknown);
  public userClaims = this.userClaimsSignal.asReadonly();
  private firestore: Firestore = inject(Firestore);
  public isInitialized = signal(false);
  usersCollection = collection(this.firestore, 'users');

  private readonly AUTH_USER_KEY: StateKey<User> = makeStateKey<User>('authUser');
  private REDIRECT_KEY = makeStateKey<string>('redirect');

  router = inject(Router);
  transferState = inject(TransferState);

  constructor() {
    this.auth.onAuthStateChanged(user => {
      if (user) {
        user.getIdTokenResult().then(tokenResult => {
          console.log(user);
          this.currentUser.set(user);
          this.userClaimsSignal.set(tokenResult.claims);
          this.checkForLoginState(user);
        }).finally(() => {
          this.isInitialized.set(true);

        });
      } else {
        this.isLoggedIn.set(false);
        this.isInitialized.set(true);
      }
    });
  }

  loadClaims(): Promise<boolean> {
    return new Promise((resolve) => {
      const unsubscribe = this.auth.onAuthStateChanged(user => {
        unsubscribe();
        if (user) {
          user.getIdTokenResult().then(tokenResult => {
            this.userClaimsSignal.set(tokenResult.claims);
            resolve(true);
          });
        } else {
          resolve(false);
        }
      });
    });
  }

  hasClaim = createMemoizedSelector((claim: string): boolean => {
    if (!this.userClaims()) return false;
    const checkClaim = (claim: string): boolean => {
      const claims = this.userClaims() as { [key: string]: unknown; };
      if (claims[claim]) return true;
      const parents = claimHierarchy[claim] || [];
      return parents.some(parentClaim => checkClaim(parentClaim));
    };
    return checkClaim(claim);
  });

  isUser = computed(() => {
    return this.hasClaim('user') && !this.hasClaim('admin');
  });

  isAdmin = computed(() => {
    return this.hasClaim('admin');
  });

  getUserRole(): 'admin' | 'user' | undefined {
    if (this.isAdmin()) {
      return 'admin';
    } else if (this.isUser()) {
      return 'user';
    } else return undefined;
  }


  checkForLoginState(user: User) {
    //ToDo: Check for emailVerified
    if (user.uid) {
      this.isLoggedIn.set(true);
    }
    else this.isLoggedIn.set(false);
  }


  async signOut() {
    sessionStorage.clear();
    this.transferState.remove(this.AUTH_USER_KEY);
    this.transferState.remove(this.REDIRECT_KEY);
    await this.auth.signOut();
    this.router.navigateByUrl(LOGIN_URL);
    console.log(LOGIN_URL);

    //ToDo: Claims nach dem Logout loeschen
    window.location.reload();
  }


  urlForward() {
    let forward = '';
    const redirect = this.transferState.get(this.REDIRECT_KEY, forward);
    if (redirect) forward = redirect;
    this.transferState.remove(this.REDIRECT_KEY);
    try {
      this.router.navigate([forward]);
    }
    catch (error: any) {
      error.header = 'Fehler beim Weiterleiten';
      this.router.navigate(['']);
    }
  }

  async updateUser(uid: string, data: any) {
    const docRef = doc(this.usersCollection, uid);
    return await setDoc(docRef, data, { merge: true });
  }

  async getUser(uid: string): Promise<AppUser | null> {
    try {
      const docRef = doc(this.usersCollection, uid);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        return docSnap.data() as AppUser;
      } else {
        console.log("No such user document!");
        return null;
      }
    } catch (error) {
      if (error instanceof Error && error.message.includes("Missing or insufficient permissions")) {
        return null;
      }
      throw error;
    }
  }

}


function createMemoizedSelector<T, R>(selector: (arg: T) => R): (arg: T) => R {
  const cache = new Map<T, R>();
  return (arg: T) => {
    if (cache.has(arg)) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      return cache.get(arg)!;
    } else {
      const result = selector(arg);
      cache.set(arg, result);
      return result;
    }
  };
}